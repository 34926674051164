const filterInit = (Filterizr) => {
	const filterContainer = document.querySelector('[data-filter-container]');
	if (!filterContainer) return;

	const options = {
		animationDuration: 0,
		delay: 0,
		// filterOutCss: {
		// 	transform: 'scale(1)',
		// },
		// filterInCss: {
		// 	transform: 'scale(1)',
		// },
	};
	let filterRun = new Filterizr(filterContainer, options);
	const filterControls = document.querySelectorAll('[data-filter-control');

	Array.from(filterControls).forEach((node) =>
		node.addEventListener('click', () => {
			filterControls.forEach((filter) => filter.classList.remove('active'));
			node.classList.add('active');
		}),
	);

	document.addEventListener('loadMoreStart', () => {
		filterRun.destroy();
		filterControls.forEach((filter) => filter.classList.remove('active'));
		filterControls[0].classList.add('active');
	});

	document.addEventListener('loadMoreEnd', () => {
		filterRun = new Filterizr(filterContainer, options);
	});
};

const blockDownloads = (Filterizr, Swiper, FreeMode) => {
	let swiperFilter = document.querySelector('.b-downloads__swiper');
	if (swiperFilter) {
		swiperFilter = new Swiper('.b-downloads__swiper', {
			modules: [FreeMode],
			slidesPerView: 'auto',
			spaceBetween: 0,
			freeMode: true,
		});
	}
	filterInit(Filterizr);
};

export default blockDownloads;
