const blockWorkers = (Swiper, Navigation) => {
	let personSwiper = document.querySelector('.c-person-swiper__swiper');
	if (personSwiper) {
		personSwiper = new Swiper('.c-person-swiper__swiper', {
			loop: true,
			modules: [Navigation],
			autoHeight: false,
			navigation: {
				nextEl: '.c-single-arrow--person.c-single-arrow--next',
				prevEl: '.c-single-arrow--person.c-single-arrow--prev',
			},
			breakpoints: {
				992: {
					autoHeight: true,
				},
			},
		});
	}

	const btnPerson = document.querySelectorAll('.c-person-swiper-slide__read');
	if (btnPerson) {
		btnPerson.forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();
				const wrapPerson = el.parentElement.parentElement;
				if (wrapPerson.classList.contains('c-person-swiper-slide__wrap--active')) {
					wrapPerson.classList.remove('c-person-swiper-slide__wrap--active');
				} else {
					wrapPerson.classList.add('c-person-swiper-slide__wrap--active');
				}
			});
		});
	}
};
export default blockWorkers;
