const footer = (Swiper, Autoplay) => {
	const block = document.querySelector('.c-footer__swiper-rewards');
	if (block) {
		const swiper = new Swiper(block, {
			modules: [Autoplay],
			slidesPerView: 2.5,
			spaceBetween: 24,
			loop: true,
			autoplay: {
				delay: 2000,
			},
			breakpoints: {
				768: {
					slidesPerView: 4.5,
				},
				1200: {
					slidesPerView: 8,
				},
			},
		});

		return swiper;
	}

	return false;
};

export default footer;
