const blockEcommere = (Swiper, Navigation) => {
	let swiper = document.querySelector('.c-swiper-ecommerce__swiper');
	if (swiper) {
		swiper = new Swiper('.c-swiper-ecommerce__swiper', {
			modules: [Navigation],
			spaceBetween: 100,
			slidesPerView: 1,
			loop: true,
			autoplay: false,
			navigation: {
				nextEl: '.c-single-arrow--ecommerce.c-single-arrow--next',
				prevEl: '.c-single-arrow--ecommerce.c-single-arrow--prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				992: {
					slidesPerView: 3,
				},

				1200: {
					slidesPerView: 4,
				},
			},
		});
	}
};

export default blockEcommere;
