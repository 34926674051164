/*
  Project: glosel
  Author: WebCrafters Studio
 */
import AOS from 'aos';
import { CountUp } from 'countup.js';
import { jarallax } from 'jarallax';
import Filterizr from 'filterizr';
import load from 'load-script';
import Swiper, { Autoplay, Navigation, Pagination, FreeMode } from 'swiper';
import accordion from './modules/accordion';
import blockDownloads from './modules/blocks/block-downloads';
import blockEcommere from './modules/blocks/block-ecommerce';
// import blockHero from './modules/blocks/block-hero';
import blockPlaces from './modules/blocks/block-places';
import blockSwiperArticles from './modules/blocks/block-swiper-articles';
import blockVideoText from './modules/blocks/block-video-text';
import blockWorkers from './modules/blocks/block-workers';
import footer from './modules/footer';
import menu from './modules/menu';
import scrollTo from './modules/scrollto';

document.addEventListener('DOMContentLoaded', () => {
	menu();
	accordion();
	footer(Swiper, Autoplay);
	blockEcommere(Swiper, Navigation);
	blockPlaces(load);
	blockWorkers(Swiper, Navigation);
	blockVideoText();
	blockSwiperArticles(Swiper, Pagination, Autoplay);
	blockDownloads(Filterizr, Swiper, FreeMode);
	blockSwiperArticles(Swiper, Autoplay);
	// blockHero();
	jarallax(document.querySelectorAll('.jarallax'), {
		speed: 0.2,
	});
	const goUp = document.querySelector('[data-go-up]');
	if (goUp) {
		goUp.onclick = () => {
			scrollTo(document.querySelector('body'));
		};
	}

	window.onload = () => {
		const counts = document.querySelectorAll('[data-count]');
		counts.forEach((el) => {
			const count = el;

			const countUp = new CountUp(el, count.dataset.count, {
				enableScrollSpy: true,
				scrollSpyDelay: 100,
				scrollSpyOnce: true,
			});
			countUp.start();
		});
	};

	document.onscroll = () => {
		const animations = document.querySelectorAll('[data-animation-text]');
		animations.forEach((el) => {
			const animation = el;
			const left = animation.clientWidth - 0.35 * window.scrollY;
			animation.style.left = `${left}px`;
		});
	};

	AOS.init({
		once: true,
	});
});
