const accordion = () => {
	const questions = document.querySelectorAll('.c-single-accordion');
	if (questions) {
		questions.forEach((el) => {
			el.addEventListener('click', (e) => {
				e.preventDefault();
				if (el.classList.contains('c-single-accordion--active')) {
					el.classList.remove('c-single-accordion--active');
				} else {
					el.classList.add('c-single-accordion--active');
				}
			});
		});
	}
};
export default accordion;
