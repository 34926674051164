const blockSwiperArticles = (Swiper, Autoplay) => {
	const swipers = document.querySelectorAll('[data-swiper-partnership]');
	if (swipers) {
		swipers.forEach((el) => {
			const swiper = new Swiper(el, {
				modules: [Autoplay],
				loop: true,
				autoplay: {
					delay: 5000,
				},
				slidesPerView: 1,
				spaceBetween: 0,
				breakpoints: {
					320: {
						slidesPerView: 2,
					},
					480: {
						slidesPerView: 3,
					},
					992: {
						slidesPerView: 6,
					},
				},
			});
			return swiper;
		});
	}
};

export default blockSwiperArticles;
