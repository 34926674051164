const blockVideoText = () => {
	const video = document.querySelector('.b-video-text__video');
	const videoWrap = document.querySelector('.b-video-text__video-wrap');
	const videoStart = document.querySelector('.b-video-text__video-box--start');
	if (video && videoStart) {
		videoWrap.addEventListener('click', () => {
			if (videoWrap.classList.contains('b-video-text__video-wrap--active')) {
				video.pause();
				videoWrap.classList.remove('b-video-text__video-wrap--active');
			} else {
				video.play();
				videoWrap.classList.add('b-video-text__video-wrap--active');
			}
		});
	}
};
export default blockVideoText;
