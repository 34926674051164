const menu = () => {
	const btn = document.querySelector('.c-menu__btn');

	if (btn) {
		btn.addEventListener('click', () => {
			if (btn.classList.contains('c-menu__btn--exit')) {
				btn.classList.remove('c-menu__btn--exit');
				document.body.classList.add('menu-active');
			} else {
				btn.classList.add('c-menu__btn--exit');
				document.body.classList.remove('menu-active');
			}
		});
	}
};
export default menu;
