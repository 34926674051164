import scrollTo from '../scrollto';
/* global google, googleMapsApiKey */

function initMarker(marker, map, div) {
	const markerEl = marker;
	const { markerLat, markerLng, markerName, markerDesc } = markerEl.dataset;
	const markerColor = '#1E5583';
	const svgDataOff = `
	<svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g filter="url(#filter0_d_626_1073)">
	<circle cx="42.5" cy="34.5" r="31.5" fill="${markerColor}"/>
	</g>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M43 36.75V36.75C40.1002 36.75 37.75 34.3997 37.75 31.5V31.5C37.75 28.6002 40.1002 26.25 43 26.25V26.25C45.8998 26.25 48.25 28.6002 48.25 31.5V31.5C48.25 34.3997 45.8998 36.75 43 36.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M43 50.75C43 50.75 30.75 40.6875 30.75 31.5C30.75 24.7345 36.2345 19.25 43 19.25C49.7655 19.25 55.25 24.7345 55.25 31.5C55.25 40.6875 43 50.75 43 50.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
	<defs>
	<filter id="filter0_d_626_1073" x="0" y="0" width="85" height="85" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
	<feFlood flood-opacity="0" result="BackgroundImageFix"/>
	<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
	<feOffset dy="8"/>
	<feGaussianBlur stdDeviation="5.5"/>
	<feComposite in2="hardAlpha" operator="out"/>
	<feColorMatrix type="matrix" values="0 0 0 0 0.117647 0 0 0 0 0.333333 0 0 0 0 0.513726 0 0 0 0.16 0"/>
	<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_626_1073"/>
	<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_626_1073" result="shape"/>
	</filter>
	</defs>
	</svg>
    `;

	const svgOff = {
		url: `data:image/svg+xml;charset=utf-8, ${encodeURIComponent(svgDataOff)} `,
		anchor: new google.maps.Point(0, 30),
	};

	const latLng = {
		lat: parseFloat(markerLat),
		lng: parseFloat(markerLng),
	};

	const markerNew = new google.maps.Marker({
		position: latLng,
		map,
		icon: svgOff,
	});

	map.markers.push(markerNew);

	if (markerDesc) {
		const contentString = `
		<div class="b-places__map-info-desc">${JSON.parse(markerName)} <br> ${JSON.parse(markerDesc)}</div>`;

		const infowindow = new google.maps.InfoWindow({
			content: contentString,
			maxWidth: 300,
			minWidth: 150,
			pixelOffset: new google.maps.Size(183, 140),
		});

		google.maps.event.addListener(markerNew, 'mouseover', () => {
			marker.classList.add('hover');
			infowindow.open(map, markerNew);
		});

		google.maps.event.addListener(markerNew, 'mouseout', () => {
			marker.classList.remove('hover');
			infowindow.close(map, markerNew);
		});

		google.maps.event.addListener(markerNew, 'click', () => {
			if (marker.classList.contains('hover')) {
				infowindow.close(map, markerNew);
			} else {
				infowindow.open(map, markerNew);
			}
			marker.classList.toggle('hover');
		});
		google.maps.event.addListener(infowindow, 'closeclick', () => {
			infowindow.close(map, markerNew);
			marker.classList.remove('hover');
		});

		markerEl.onclick = () => {
			map.panTo(markerNew.getPosition());
			map.setZoom(14);
			scrollTo(div, true);
		};
	}
}

function centerMap(map) {
	const bounds = new google.maps.LatLngBounds();
	map.markers.forEach((marker) => {
		bounds.extend({
			lat: marker.position.lat(),
			lng: marker.position.lng(),
		});
	});

	if (map.markers.length === 1) {
		map.setCenter(bounds.getCenter());
	} else {
		map.fitBounds(bounds);
	}
}

export function initMap() {
	const mapInit = document.querySelector('[data-places-map-init]');
	const mapRoot = document.querySelector('[data-places-map-root]');
	const markers = mapRoot.querySelectorAll('[data-marker]');

	const mapArgs = {
		zoom: 16,
		zoomControl: true,
		mapTypeControl: false,
		scaleControl: true,
		streetViewControl: true,
		rotateControl: false,
		fullscreenControl: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
	};
	const map = new google.maps.Map(mapInit, mapArgs);

	map.markers = [];
	markers.forEach((el) => {
		const marker = el;
		initMarker(marker, map, mapInit);
	});

	centerMap(map);

	// Return map instance.
	// return map;
}

const blockPlaces = (load) => {
	if (typeof googleMapsApiKey === 'undefined') return;
	window.initMap = initMap;
	load(`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&callback=initMap&v=weekly`);
};

export default blockPlaces;
